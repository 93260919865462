import {userFunction} from '../userFunction';
import {commonResult} from 'middlewares/userScript/results';
import {D5FormButton} from '../elems';
import {BUTTON_TYPE} from 'services/interfaces/global-interfaces';
import {createCopiedAllFormButtonItems} from "../utils";

function getArguments(processedForm, name, inheritMethod, usArguments) {
  const currButton = new D5FormButton({
    name,
    formItems: createCopiedAllFormButtonItems(processedForm)
  });

  const result = [currButton, inheritMethod];

  if (currButton.type === BUTTON_TYPE.GOTO) {
    result.push(usArguments.apiFilter);
  }

  if (currButton.type === BUTTON_TYPE.REPORT_GROUP) {
    result.push(usArguments);
  }

  result.push(usArguments.buttonEvents);

  return result;
}

/**
 * Event fires after a button on a form was clicked (not Grid ToolBar)
 * @param {Object} args
 * @param {ItemsState} args.items
 * @param {GroupsState} args.groups
 * @param {function} args.dispatch
 * @param {FormObject[]} args.subForms
 * @param {number} args.eventType
 * @param {FormObject} args.parentForm
 * @param {FormObject} args.processedForm
 * @param {string} args.name
 * @param {Object} args.usArguments  Объект аргументов для юзер скриптов
 * @param {function(): Promise<any>} [args.inheritMethod] - стандартная функция с тулбара таблицы. Возвращает промис
 *  который свидетельствует об окончании исполнения юзер скрипта.
 */
export const onTableBtnClick = ({
  items,
  groups,
  eventType,
  subForms,
  processedForm,
  dispatch,
  parentForm,
  name,
  inheritMethod,
  usArguments,
  clickEventName = ''
}) => {
  const {formData} = processedForm;

  return userFunction({
    items,
    groups,
    subForms,
    dispatch,
    formData,
    eventType,
    name,
    processedForm,
    customClickEvent: clickEventName,
    parentForm,
    args: getArguments(processedForm, name, inheritMethod, usArguments)
  }).then(result => {
    commonResult({
      result,
      processedForm
    });
  });
};
