import {AbstractStateStorage, StorageStringified} from './AbstractStateStorage';
import getFiltersKey, {filterKeyToFormKey} from '../../services/SecondaryMethods/filterUtils/getFiltersKey';
import {isDefined, isEmptyValue} from '../../services/SecondaryMethods/typeUtils';
import {
  getSessionFilters,
  getStorageFilter,
  setSessionFilters,
  setStorageFilter
} from '../../services/SecondaryMethods/filterUtils';
import {MaximizeFilter, maximizeFilters, MinimizeFilter, minimizeFilters} from '../storageConverter/convertFilters';
import FiltersDB from '../../indexedDB/FiltersDB';
import parseJSONWithTranslation from '../parseJSONWithTranslation';
import {FiltersDBItem} from '../../indexedDB';

export default class FilterStateStorage extends AbstractStateStorage {
  private readonly _formKey: string;

  constructor({userID, formKey}: {userID?: number; formKey: string}) {
    super({userID: userID!});
    this._formKey = formKey;
  }

  protected calcKey(userID: number, formKey: string): string {
    return isDefined(userID) ? getFiltersKey(formKey, () => userID) : getFiltersKey(formKey);
  }

  public copyFilters(formKey: string, toLocalStorage: boolean = true, userId?: number) {
    const getUserID = userId == null ? undefined : () => userId;

    if (toLocalStorage) {
      return setStorageFilter(formKey, getSessionFilters(formKey, getUserID), getUserID);
    }
    setSessionFilters(formKey, getStorageFilter(formKey, getUserID), getUserID);
  }

  private getUserCallback() {
    if (this._userID == null) return;
    return () => this._userID;
  }

  static saveFilterByFormKeyToDb(formKey: string, minimizedFilter: Record<string, MinimizeFilter>, userId?: number) {
    const filterID = FilterStateStorage.lsName(formKey, userId);
    return FiltersDB.put({id: filterID, filters: minimizedFilter});
  }

  static async exportById(filterId: string) {
    const [item] = await FiltersDB.getById([filterId]);
    return maximizeFilters(item?.filters || {});
  }

  static async exportByFormKeys(
    formKeys: string[],
    userID: number
  ): Promise<Record<string, Record<string, MaximizeFilter>>> {
    const filterIds = formKeys.map(formKey => {
      return FilterStateStorage.lsName(formKey, userID);
    });
    const result = await FiltersDB.getById(filterIds);
    return result
      .map(item => {
        return {
          formKey: filterKeyToFormKey(item.id!, userID),
          maximizedFilters: maximizeFilters(item.filters)
        };
      })
      .reduce(
        (acc, item) => {
          acc[item.formKey] = item.maximizedFilters;
          return acc;
        },
        {} as Record<string, Record<string, MaximizeFilter>>
      );
  }

  /**
   * Спочатку експортується FiltersDB, бо там всі дані збережені.
   * Далі експортується localStorage, бо там зберігається частина фільтрів з попередньої сесії.
   * Вкінці експортується sessionStorage, бо там зберігається частина фільтрів з поточної сесії з якими працювали зараз.
   */
  public async exportAll(): Promise<StorageStringified> {
    if (!this._userID) throw new TypeError('UserId is not specified');

    const key = this.calcKey(this._userID, ''); //string :userid-filters-

    const allDBFilters = await FiltersDB.getAllByUser(this._userID);

    const localStorageFilters: FiltersDBItem[] = this.mapStorage(localStorage, itemKey => {
      const formKey = itemKey.replace(key, '');
      return {id: itemKey, filters: getStorageFilter(formKey, this.getUserCallback())};
    });

    const sessionFilters: FiltersDBItem[] = this.mapStorage(sessionStorage, itemKey => {
      const formKey = itemKey.replace(key, '');
      return {id: itemKey, filters: getSessionFilters(formKey, this.getUserCallback())};
    });

    const mergedFiltersMap = new Map<string, FiltersDBItem>();

    allDBFilters.forEach(filter => {
      mergedFiltersMap.set(filter.id!, filter);
    });

    localStorageFilters.forEach(filter => {
      mergedFiltersMap.set(filter.id!, filter);
    });

    sessionFilters.forEach(filter => {
      mergedFiltersMap.set(filter.id!, filter);
    });

    return Array.from(mergedFiltersMap.values()).map(({id, filters}) => {
      return [this.itemKeyForExport(id!), JSON.stringify(filters)];
    });
  }

  /**
   * ЛОГІКА ЗБЕРЕЖЕННЯ ФІЛЬТРАЦІЇ
   * Всі фільтри постійно зберігаються в ДБ.
   * Під час роботи застосунку використовується sessionStorage, для того, щоб можна було виконувати фільтрацію в різних вкладках одночасно.
   * Після закриття сторінки або форми фільтри переносяться з sessionStorage в localStorage.
   * При наступному відкритті застосунку/авторизації всі фільтри з localStorage переносяться в ДБ для постійного зберігання і видаляються з localStorage.
   * При відкритті форми фільтри беруться з sessionStorage, якщо там немає, то в ДБ.
   * Таким чином в sessionStorage та localStorage зберігаються тільки фільтри тих форм які були використані під час сесії. Решта зберігається в ДБ.
   */
  public async importAll(states: StorageStringified = []): Promise<void> {
    const data = states
      .map(([key, state]) => {
        return {
          filterID: this._userID + key,
          minimizedFilter: minimizeFilters(parseJSONWithTranslation(state))
        };
      })
      .filter(e => !isEmptyValue(e.minimizedFilter));

    for (const [key] of states) {
      const lsKey = this._userID + key;
      localStorage.removeItem(lsKey);
      sessionStorage.removeItem(lsKey);
    }

    return FiltersDB.putArray(data);
  }

  get lsName(): string {
    return this.calcKey(this._userID, this._formKey);
  }

  /**
   * @param formKey
   * @param toLocalStorage - якщо не вказано, то по замовчуванню toLocalStorage true
   * @param userID
   */
  static copyFilters(formKey: string, toLocalStorage?: boolean, userID?: number) {
    new FilterStateStorage({userID, formKey}).copyFilters(formKey, toLocalStorage, userID);
  }

  static async removeFiltersFromStorages(formKey: string, userID?: number) {
    const lsName = FilterStateStorage.lsName(formKey, userID);
    sessionStorage.removeItem(lsName);
    localStorage.removeItem(lsName);
    await FiltersDB.remove(lsName);
  }

  static lsName(formKey: string, userID?: number) {
    return new FilterStateStorage({userID, formKey}).lsName;
  }

  static setFiltersToSession(formKey: string, userID: number, maximizedFilters: Record<string, MaximizeFilter>) {
    if (userID == null || isEmptyValue(maximizedFilters)) return;

    return setSessionFilters(formKey, maximizedFilters, () => userID);
  }
}
