import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS} from '../interfaces/global-interfaces';

export const actionTypes = {
  REMOVE_FILTER: 'filters/REMOVE_FILTER',
  SET_FILTERS: 'filters/SET_FILTERS',
} as const;

export interface FilterStateObject {
  value?: any;
  operation: FILTER_OPERATIONS;
  hasIsBlank?: boolean;
  type: FIELD_EDITOR_TYPE;
  isTable: boolean;
  requestField: string;
  isCustomizable?: boolean;
  dataSource?: any[];
  displayValue?: any;
  required: boolean;
  modified?: boolean;
  isCustomConditionList?: boolean;
  customConditionList?: any[];
}

export type FormFilterState = Record<string, FilterStateObject>;
export type FiltersState = Record<string, FormFilterState>;
