import {TitleType} from 'services/interfaces/global-interfaces';
import {ButtonColorScheme, ButtonStylingMode} from '../../services/SecondaryMethods/formItems/itemInterfaces';

export {default as toolBarButtonProps} from 'utilsOld/toolbar_buttons/ToolBarButton';
export {default as toolBarMenuProps} from 'utilsOld/toolbar_buttons/ToolBarMenu';
export {default as DefaultButtonOptions} from 'utilsOld/toolbar_buttons/defaultButtonOptions';

export enum ActionType {
  Add = 'add',
  ArrowBottom = 'arrowBottom',
  ArrowRight = 'arrowRight',
  ArrowTop = 'arrowTop',
  ArrowLeft = 'arrowLeft',
  ClearQuickFilter = 'clearQuickFilters',
  Copy = 'copy',
  ToggleTotals = 'toggleTotals',
  ToggleTotalsBySelected = 'toggleTotalsBySelected',
  ToggleTotalsAllData = 'toggleTotalsAllData',
  Delete = 'del',
  Deselect = 'deselect',
  Edit = 'edit',
  View = 'view',
  Export = 'export',
  ExportAll = 'exportAll',
  ExportSelected = 'exportSelected',
  GoTo = 'goto',
  Preferences = 'preferences',
  Refresh = 'refresh',
  Report = 'report',
  RevertTableOptions = 'revertTableOptions',
  RevertButtonsOptions = 'revertButtonsOptions',
  RevertFormOptions = 'revertFormOptions',
  SelectAll = 'selectAll',
  ShowModalFilter = 'showModalFilter',
  SelectFilterTemplate = 'selectFilterTemplate',
  SetIsPassed = 'setIsPassed',
  ClearIsPassed = 'clearIsPassed',
  MultiEdit = 'multiEdit',
  AddChild = 'addChild',
  LoadAll = 'loadAll',
  CopyFilterAsURL = 'copyFilterAsURL',
  ReportGroup = 'reportGroup',
  ShowFilterDockPanel = 'showFilterDockPanel',
  ShowEditDockPanel = 'showEditDockPanel',
  ExpandAll = 'expandAll',
  CollapseAll = 'collapseAll',
  SetFilterByCell = 'setFilterByCell',
  DeleteAllNotifications = 'DeleteAllNotifications',
  MarkAllNotificationsAsRead = 'MarkAllNotificationsAsRead',
  ReceiveNotifications = 'ReceiveNotifications'
}

export type ButtonID = number | string;

export interface ToolBarButtonOptions {
  id: number;
  text: string;
  name: string;
  elementAttr: Record<string, any>;
  hint: string | undefined;
  help: string;
  disabled: boolean | undefined;
  className?: string | undefined;
  iconType: string | undefined;
  defaultIcon: string | undefined;
  defaultTitle: string | undefined;
  groupID: number | null;
  hotkey: string | undefined;
  order: number | undefined;
  items?: ToolBarButtonOptions[];
  titleType: TitleType;
  isPressed: boolean;
  colorScheme: ButtonColorScheme;
  stylingMode: ButtonStylingMode;
  action: ActionType;
  location?: string;
  locateInMenu: string;
  formID?: string;
  helpArticleGuid?: string;
  visible: boolean;
  beginGroup: boolean;
  formButtonFillingRules?: any;
  splitButton?: boolean;
  docUrl?: string;
  template?: (data: ToolBarButtonOptions, element: HTMLElement) => any;
  onClick?: (comData: Record<string, any>) => void;
  onClickCustom: (comData: Record<string, any>, action: ActionType, id: ButtonID) => void;
  formOpenMode?: number | undefined;
  widget?: ToolBarItemWidget;
  disableContextMenu?: boolean;
  showArrowIcon?: boolean;
}

export enum ToolBarItemWidget {
  Button = 'dxButton',
  Menu = 'dxMenu',
  Section = 'section',
}

export interface ToolBarItem {
  cssClass?: string;
  options: ToolBarButtonOptions;
  disabled?: boolean;
  location?: string;
  locateInMenu?: string;
  template?: (data: ToolBarButtonOptions, element: HTMLElement) => any;
  widget: ToolBarItemWidget;
  visible: boolean;
  beginGroup: boolean;
}
