import {createSelector} from 'reselect';
import {RootState} from '../../store';

export const userSelector = (state: RootState) => state.user;

export const makeUserNameSelector = () => {
  return createSelector(userSelector, user => {
    return user.Name;
  });
};
export const makeUserInfoSelector = () => {
  return createSelector(userSelector, user => {
    return {
      name: user.Name,
      email: user.Email,
      login: user.login,
      phone: user.Phone,
      publicKey: user.PublicKey,
      photo: user.Photo,
      userID: user.UserID
    };
  });
};

export const makeUserIsAdminSelector = () => {
  return createSelector(userSelector, user => {
    return !!user.IsAdmin;
  });
};

export const makeUserIsConfiguratorSelector = () => {
  return createSelector(userSelector, user => {
    return !!user.IsConfigurator;
  });
};

export const makeUserIsOwnerSelector = () => {
  return createSelector(userSelector, user => {
    return !!user.IsOwner;
  });
};

export const isAuthSelector = (state: RootState): boolean => state.user.isAuth;
export const userIdSelector = (state: RootState): number => state.user.UserID! ?? state.user.ID;
export const userNameSelector = (state: RootState): string => state.user.Name;
export const loginSelector = (state: RootState): string => state.user.login;
export const googleAuthEmailSelector = (state: RootState): string | null => state.user.GoogleAuthEmail;
export const isNotifySelector = (state: RootState): boolean => !!state.user.IsNotify;
