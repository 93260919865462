import {fields, NOT_FOUND_ROUTE, SINGLE_FORM} from '../objects';
import {D5Error} from './errors';
import {getRequestColumns} from 'utilsOld/getRequestColumns';
import {isSingleFormRoute} from '../../utilsOld/routes/routes';

export {getQueryParams} from './getQueryParams';
export {getFormReq, getSubsystemReq} from './constRequests';
export {validateGroup} from './validateGroup';

export function getNestedColumns(formID, sysForms, sysSubForms) {
  const nestedSubForms = sysSubForms.filter(sf => sf.FormID === formID && sf[fields.NestedFieldID_Name]);

  return nestedSubForms.reduce((acc, sf) => {
    const id = sf[fields.DetailFormID];
    const nestedFieldName = sf[fields.NestedFieldID_Name];
    const form = findSysForm(id, sysForms);
    if (form) {
      acc[nestedFieldName] = {
        Columns: getRequestColumns(form.Sys_FormFields, form[fields.ObjectID_KeyObjectFieldID_Name]),
        Sorts: form[fields.FixedOrderField] ? [form[fields.FixedOrderField]] : undefined,
      };
      return acc;
    }

    const detailName = sf[fields.DetailFormID_Name];
    D5Error.log('W1015', [detailName]);
    return acc;
  }, {});
}

export function findSysForm(id, sysForms = []) {
  return sysForms.find(sysForm => {
    return sysForm.ID.toString() === String(id);
  });
}

/**
 * @param id
 * @param {SysSubForm[]} subForms
 * @return {*}
 */
export function findSysSubForms(id, subForms) {
  return subForms.filter(sf => String(sf[fields.FormID]) === String(id));
}
export function navigateTo404() {
  if (isSingleFormRoute(window.location.hash)) {
    window.location.replace(`/#${SINGLE_FORM}/${NOT_FOUND_ROUTE}`)
  }
  return window.location.replace(`/#/${NOT_FOUND_ROUTE}`);
}
