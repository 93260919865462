import {trigger} from 'devextreme/events';
import eventEngine from 'devextreme/events/core/events_engine';
import {system} from 'services/objects';
import {createEditUrl, createParentRoute} from 'utilsOld/routes';
import {getEditFormData} from 'utilsOld/common_requests';
import {cellStyle} from '../cellMethods';
import {Messages} from 'services/lang/messages';
import {copyTextToClipboard} from 'utilsOld/copyTextToClipboard';
import {showErrorNotification, showSuccessNotification} from 'services/SecondaryMethods/snackbars';
import cn from 'classnames';
import {FormCreateMode} from '../../../../services/interfaces/global-interfaces';

const {d5LinkClass} = system.COMPONENT_CLASSES;
const {
  LINKS: {
    TYPES: {LINK, FORM, GOTO}
  }
} = system;

const { EDIT} = FormCreateMode;

export function formContainerClick({formName, key, target = '_blank', features, callback, loadFun, URLField}) {
  return async e => {
    const location = document.location;
    const parentRoute = createParentRoute(location.hash);
    if (e.ctrlKey || e.button === 1) {
      target = '_blank';
    }
    if (typeof callback !== 'function') {
      // в случае если callback вернул (props = undefined) - переходим по URLField
      // в остальных случаях строим ссылку через createEditUrl
      callback = props =>
        window.open(
          key && props.formName
            ? `#${createEditUrl({
                parentRoute,
                mode: EDIT,
                formName,
                id: key
              })}`
            : URLField,
          target,
          features
        );
    }

    if (typeof loadFun !== 'function') {
      loadFun = () => {
        /**
         * data for LinkType [FORM] - to get EditFormName
         */
        return getEditFormData(formName, key);
      };
    }

    await loadFun()
      .then(callback)
      .catch(err => console.error('Wrong data in displayCustomParam', err));
  };
}

export function createDomLink(options) {
  const {LINE_HEIGHT} = system.DEFAULT_LOCALS;
  /**
   * destructuring of customParams if they are exist
   */
  //контейнер для дивов (с переходом по onClick) в которых нет прямого тега <a/>
  /**
   * need for adding 3 dots to the end of last row in cell
   */
  const lineClampValue = options.maxLineCount !== 0 ? `${options.maxLineCount}` : '1';
  /**
   * maxHeight => 1em default case and need to set whiteSpace - nowrap and maxHeight - 1em
   * @returns {string | boolean}
   */
  const defaultValue = `calc(${LINE_HEIGHT}em + 1px)`;

  const setMaxHeight = options.setMaxHeight !== defaultValue ? options.setMaxHeight : false;

  const div = document.createElement('div');
  const toolTipDiv = document.createElement('div');
  cellStyle(div, lineClampValue, setMaxHeight);

  let link = document.createElement('a');
  link.className = d5LinkClass;

  link.setAttribute('data-open-mode', options.dataOpenMode);

  link.oncontextmenu = e => {
    e.preventDefault();

    if (!e.columnContextItems) {
      const ee = eventEngine.Event(e, {
        columnContextItems: [
          {text: Messages.Controls.Open, onClick: formContainerClick(options)},
          {text: Messages.Controls.OpenInNewTab, onClick: formContainerClick({...options, target: '_blank'})},
          {
            beginGroup: true,
            text: Messages.Controls.CopyText,
            onClick: () => {
              copyTextToClipboard(options.value)
                .then(() => showSuccessNotification(Messages.Success.Copied))
                .catch(err => showErrorNotification({title: Messages.Errors.ErrorOccurred, msg: err.message}));
            }
          }
        ]
      });
      e.stopPropagation();
      trigger(link, ee);
    }
  };

  link.onmousedown = e => {
    e.preventDefault();
    if (e.button !== 2) formContainerClick(options)(e);
  };
  link.append(options.value);

  div.append(toolTipDiv);
  div.append(link);
  return div;
}

export function createJsxLink(options) {
  return (
    <div className={'dx-datagrid'}>
      <div
        className={cn('jsx-decoration-link', d5LinkClass)}
        onClick={formContainerClick(options)}
        data-open-mode={options.dataOpenMode}
      >
        {options.value}
      </div>
    </div>
  );
}

export function getDecorationLinkTitle(linkType, linkTitle, url, formName, title) {
  switch (linkType) {
    case LINK:
      return linkTitle || url || title;
    case FORM:
      return linkTitle || formName || title;
    case GOTO:
      return linkTitle || formName || title;
    default:
      return linkTitle || url || formName || title;
  }
}

export const parseDecorationParams = ({ displayCustomParam, value='', title }) => {
  const {
    LinkType: linkType,
    URLField,
    OpenMode: openMode,
    IDField: fieldName,
    Form: formName,
    FilterValue: filterValue,
    DestinationFilter: destFilterName,
    SourceField: sourceField,
    LinkTitle: linkTitle,
    TitleField: titleField
  } = JSON.parse(displayCustomParam || '{}');

  const url = URLField ?? value;
  const configuredTitle = linkTitle || titleField;
  const displayedTitle = getDecorationLinkTitle(linkType, configuredTitle, url, formName, title);

  return {
    linkType,
    url,
    openMode,
    fieldName,
    formName,
    filterValue,
    destFilterName,
    sourceField,
    displayedTitle
  };
};