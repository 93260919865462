import store from 'store';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

const defaultGetUser = (): number => store.getState().user.UserID!;

/**
 * Создает ключ для фильтров
 * При логауте в сторе user чистится и UserID - undefined. Проставляется руками.
 * @param getUser - по умолчанию берет значение со стора, можно переопределить
 * @param formKey - ключ формы
 */
function getFiltersKey(formKey: string, getUser: () => number = defaultGetUser): string {
  let userID = getUser().toString();
  userID = isDefined(userID) ? userID + '-' : '';
  return userID + 'filters-' + formKey;
}


export const filterKeyToFormKey = (filterKey: string, userId: number) => {
  const prefix = `${userId}-filters-`;
  if (!filterKey.startsWith(prefix)) {
    throw new Error('Invalid input format for filter key');
  }

  const content = filterKey.slice(prefix.length);
  const parts = content.split('/');

  const [formID, parentFormID] = parts;
  if (!formID) {
    throw new Error('No valid IDs found in the input string');
  }

  if (parentFormID) {
    return `${formID}/${parentFormID}`;
  }

  return formID;
}


export default getFiltersKey;
