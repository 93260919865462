import {Column, SelectColumn} from 'components/TableCoreOld/columns/index';
import {Messages} from 'services/lang/messages';
import {valueToArray} from 'utilsOld/valueCasters';
import DisplayType from 'utilsOld/systemObjects/DisplayType';
import {isArray} from 'services/SecondaryMethods/typeUtils';
import {getMaxTagsCount, getStylingArrays} from './utils';
import {buttonColorSchemeToDX, buttonStylingModeToDX} from '../../toolbar/utils';
import {columnType} from 'utilsOld/helpers';
import {system} from 'services/objects';
import {ALIGN_STRINGS, TEXT_ALIGNMENT} from 'services/interfaces/global-interfaces';
import {callOnce, reactSelectFieldDS} from '../../../utilsOld/createFieldDataSource';
import {userScriptActions} from '../../../services/currentForms/userScriptActions';
import {ELayoutType} from '../../../services/SecondaryMethods/formItems/itemInterfaces';
import {ColumnClass} from '../types';

import './TagsColumn.scss';

export const MULTI_HEIGHT = 28;

const {
  ENUM_DS: {VALUE_EXPR, DISPLAY_EXPR}
} = system;

const createDiv = (col, title, isOneValuePerLine, TagColorScheme, TagStylingMode) => {
  const container = document.createElement('div');
  container.classList.add('multiselect-container');

  if (isOneValuePerLine) {
    container.classList.add('multiselect-w100');
  }

  container.classList.add(`styling-mode-${buttonStylingModeToDX(TagStylingMode)}`);
  container.classList.add(`color-scheme-${buttonColorSchemeToDX(TagColorScheme)}`);

  col.appendChild(container);
  const element = document.createElement('div');
  element.classList.add('multiselect-cell');
  container.dataset.hint = title;
  container.dataset.docUrl = '';
  container.appendChild(element);
  return element;
};

const createTag = (col, title, isOneValuePerLine, TagColorScheme, TagStylingMode) => {
  const element = createDiv(col, title, isOneValuePerLine, TagColorScheme, TagStylingMode);
  const textPart = document.createElement('span');
  textPart.classList.add('multiselect-text');
  textPart.innerHTML = title;
  element.appendChild(textPart);
};

export const getTagsColumn = ({
  displayValue,
  maxTagsCount,
  isOneValuePerLine,
  TagColorScheme,
  TagStylingMode,
  textAlignment,
  tagColorSchemeArr,
  tagStylingModeArr
}) => {
  const alignmentValue = textAlignment ?? ALIGN_STRINGS[TEXT_ALIGNMENT.LEFT];
  const arr = valueToArray(displayValue);

  const col = document.createElement('div');
  col.classList.add('multiselect-column');
  col.classList.add(alignmentValue);
  let length = getMaxLength(arr, maxTagsCount);

  for (let i = 0; i < length; i++) {
    const title = arr[i];
    const oneTagColorScheme = tagColorSchemeArr[i];
    const oneTagStylingMode = tagStylingModeArr[i];
    createTag(col, title, isOneValuePerLine, oneTagColorScheme ?? TagColorScheme, oneTagStylingMode ?? TagStylingMode);
  }

  setMoreTags(maxTagsCount, arr, col, isOneValuePerLine, TagColorScheme, TagStylingMode);
  return col;
};

const getMaxLength = (arr, maxTagsCount) => {
  let length = arr.length;
  if (maxTagsCount) {
    length = maxTagsCount < arr.length ? maxTagsCount - 1 : arr.length;
  }

  return length;
};

const setMoreTags = (maxTagsCount, arr, container, isOneValuePerLine, TagColorScheme, TagStylingMode) => {
  if (maxTagsCount && arr.length > maxTagsCount) {
    const restTags = arr.slice(maxTagsCount - 1);
    const title = restTags.join(', ');
    const element = createDiv(container, title);
    const parentMoreTag = element.parentElement;
    if (isOneValuePerLine && parentMoreTag) {
      parentMoreTag.classList.add('multiselect-w100');
    }
    parentMoreTag.classList.remove('styling-mode-text', 'color-scheme-main-scheme');
    parentMoreTag.classList.add(`styling-mode-${buttonStylingModeToDX(TagStylingMode)}`);
    parentMoreTag.classList.add(`color-scheme-${buttonColorSchemeToDX(TagColorScheme)}`);
    parentMoreTag.classList.add('more-tag');
    const textRest = document.createElement('span');

    textRest.innerHTML = `${arr.length - maxTagsCount + 1} ${Messages.Controls.More.toLowerCase()}`;
    element.appendChild(textRest);
  }
};

export const tagsColumnCellTemplate =
  ({
    maxTagsCount,
    isOneValuePerLine,
    TagColorScheme,
    TagStylingMode,
    valExpr = '',
    displayExpr = '',
    displayType,
    maxLineCount,
    textAlignment,
    LookupTagColorSchemeFieldName,
    LookupTagStylingModeFieldName
  }) =>
  (cellElement, {column, data, displayValue}) => {
    const columnName = column.displayField;
    let dataDisplayValue = data[columnName];

    const getEnumDisplayValue = () => {
      if (DisplayType.isEnum(displayType)) {
        const ds = column.lookup.dataSource() || [];
        const columnDataField = column.dataField;
        const dataValue = data[columnDataField];
        return isArray(dataValue) && dataValue?.map(value => ds.find(item => item[valExpr] === value)?.[displayExpr]);
      }

      return null;
    };

    const enumDisplayValue = getEnumDisplayValue();

    if (!displayValue && !dataDisplayValue && !enumDisplayValue) {
      return;
    }

    if (dataDisplayValue && !Array.isArray(dataDisplayValue)) {
      dataDisplayValue = valueToArray(dataDisplayValue).map(item => item?.toString().trim());
    }

    displayValue = dataDisplayValue || enumDisplayValue || displayValue;

    const {tagColorSchemeArr, tagStylingModeArr} = getStylingArrays({
      tagStylingModeFieldName: LookupTagStylingModeFieldName,
      tagColorSchemeFieldName: LookupTagColorSchemeFieldName,
      columnName: column.dataField,
      data
    });

    const col = getTagsColumn({
      displayValue,
      maxTagsCount,
      isOneValuePerLine,
      TagColorScheme,
      TagStylingMode,
      textAlignment,
      tagColorSchemeArr,
      tagStylingModeArr
    });
    // MULTI_HEIGHT(28) + 2 - висоту відображення в таблиці розраховуємо виходячи із висоти рядка 30рх + 2px внутрішні відступи
    col.style.height = `${(maxLineCount * (MULTI_HEIGHT + 2)) + 2}px`;
    cellElement.appendChild(col);
  };

export default class TagsColumn {
  constructor(args) {
    this._column = args.field.isLookup() ? new SelectColumn(args) : new Column(args);
    this.field = args.field;
  }

  prop() {
    const options = this._column.prop();
    /**
     * @type {number| boolean}
     * */
    let maxLineCount = options.userData.maxLineCount;
    let dictObj = this.field.getLinkedName();
    let {
      displayType,
      columnTextAlignment,
      multiselectViewCount,
      isMultiSelect,
      isOneValuePerLine,
      TagColorScheme,
      TagStylingMode,
      isServerMultiSelect,
      LookupTagColorSchemeFieldName,
      LookupTagStylingModeFieldName
    } = this.field;

    const lookupAddon = this.field.isLookup()
      ? {
          lookup: {
            ...this._column.prop().lookup,
            dataSource: reactSelectFieldDS({
              dictObj: this.field.getLinkedName(),
              dictKeyFld: this.field.getKeyObjFieldName(),
              dictDisplayFld: this.field.displayField,
              formKey: `${this.field.formID}`,
              formID: this.field.formID,
              name: this.field.name,
              sortValues: this.field.sortValues,
              colorSchemeFieldName: LookupTagColorSchemeFieldName,
              stylingModeFieldName: LookupTagStylingModeFieldName,
              onInitFunction: callOnce(({formID, formKey, name}) => {
                return userScriptActions.onInitLookupField({
                  formID,
                  formKey,
                  name,
                  componentInitiator: ELayoutType.TABLE
                });
              })
            })
          }
        }
      : {};

    return {
      ...this._column.prop(),
      ...lookupAddon,
      cssClass: ColumnClass.Tags,
      d5Type: this.field.d5EditorType(),
      displayExpr: this.field.displayField ?? DISPLAY_EXPR,
      valueExpr: this.field.getKeyObjFieldName() ?? VALUE_EXPR,
      dictObj,
      allowSorting: false,
      editCellTemplate: 'tags-column-editor-template',
      fieldType: columnType(this.field.fieldType),
      isAnyOf: true,
      isMultiSelect: isServerMultiSelect,
      LookupTagColorSchemeFieldName,
      LookupTagStylingModeFieldName,
      TagColorScheme,
      TagStylingMode,
      multiselectViewCount,
      maxLineCount,
      cellTemplate: tagsColumnCellTemplate({
        maxTagsCount: getMaxTagsCount(multiselectViewCount),
        isMultiSelect,
        isOneValuePerLine,
        TagColorScheme,
        TagStylingMode,
        LookupTagColorSchemeFieldName,
        LookupTagStylingModeFieldName,
        valExpr: VALUE_EXPR,
        displayExpr: DISPLAY_EXPR,
        maxLineCount,
        displayType,
        textAlignment: columnTextAlignment
      })
    };
  }
}
