import {fields, system} from 'services/objects';
import {Messages} from 'services/lang/messages';
import FilterField from '../systemObjects/FilterField';
import {createFilterObj, getFilterName} from 'services/SecondaryMethods/filterUtils';
import {D5Error} from 'services/SecondaryMethods/errors';
import {postSysForms} from 'services/requests/operation';
import {fetchFilterTemplatesAction} from 'services/filterTemplates/operation';
import store from 'store';
import {getSysField} from '../sysFormUtils';
import {FormField} from 'utilsOld/systemObjects';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {getFormKey} from 'services/SecondaryMethods/getFormKey';
import MouseEventHelper from '../MouseEventHelper';
import {isArray, isDefined} from 'services/SecondaryMethods/typeUtils';
import FilterStateStorage from '../settingsStorage/FilterStateStorage';
import {FILTER_OPERATIONS_ID} from '../../services/interfaces/global-interfaces';
import {minimizeFilters} from '../storageConverter/convertFilters';

const {
  LINKS: {
    OPEN: {IN_CURRENT, IN_NEW_TAB, IN_NEW_WINDOW}
  }
} = system;

/**
 * @param formName {string}
 * @param filters {FilterGotoRules[]} - значения из ButtonFillingRules или DisplayCustomParams
 * @returns {Promise<*[]>}
 */
export async function loadDataForGoto({formName, filters}) {
  const destinationFilterNames = filters.map(item => item.destination);

  const actionForm = (await store.dispatch(postSysForms({formName}))).find(
    form => form[fields.Name] === formName
  );
  await store.dispatch(fetchFilterTemplatesAction({formName}));

  const formFields = actionForm.Sys_FormFields;
  const filterFields = (actionForm.Sys_FormFilterFields || []).filter(item =>
    destinationFilterNames.includes(item[fields.Name])
  );

  if (!filterFields.length) {
    throw D5Error.create('E1005', [destinationFilterNames, formName]);
  }

  return [actionForm, formFields, filterFields];
}

/**
 * Обновляет в указанной форме фильтры при переходе через GoTo
 * @param {string} formID
 * @param {SysFormFilterFields} filterObj
 * @param {*} filter - значение фильтра
 */
async function updateFilterGoto({formID, filterObj: sysFilterField, filter}) {
  const {NUMBER, TEXT} = system.FIELD_TYPE;

  const filterField = new FilterField(sysFilterField);

  const formKey = getFormKey(formID);

  let operation = '=';

  if ([NUMBER, TEXT].includes(filterField.fieldType) && !filterField.isLookup()) {
    operation = 'isanyof';
  }

  const filterName = getFilterName({
    objectFieldIDName: filterField.objectFieldIDName,
    name: filterField.name
  });

  let newFilter = {
    ...createFilterObj({
      objectFieldIDName: filterField.objectFieldIDName,
      name: filterField.name,
      required: filterField.required,
      type: filterField.d5EditorType(),
      isCustomizable: filterField.isCustomizable,
      isCustomConditionList: filterField.isCustomConditionList,
      customConditionList: filterField.customConditionList
    })
  };

  const formStorageFilters = await FilterStateStorage.exportById(FilterStateStorage.lsName(formKey));

  await FilterStateStorage.saveFilterByFormKeyToDb(
    formKey,
    minimizeFilters({
      ...formStorageFilters,
      ...newFilter,
      [filterName]: {
        ...newFilter[filterName],
        value: filter,
        operation
      }
    })
  );
}

/**
 * Обновляет фильтр формы по всем правилам filterGotoRules.
 * @param {FilterGotoRules[]} filterGotoRules
 * @param {SysFormFilterFields[]} filterFields
 * @param {SysForm} actionForm
 * @param {SysFormFields[]} actionFormFields
 * @return Promise<void>
 */
export async function updateFilterByRules(filterGotoRules, filterFields, actionForm, actionFormFields) {
  const rules = filterGotoRules.map(rule => {
    const filterObj = filterFields.find(filterField => filterField[fields.Name] === rule.destination);
    const value = checkDestinationValue(new FilterField(filterObj), rule.value);

    return {
      ...rule,
      value
    };
  });

  //TODO фильтры строятся по formKey (а используем actionForm.ID) -
  // нет возможности переделать потому (в логике построения ссылки нет доступа к этим данным)
  await FilterStateStorage.removeFiltersFromStorages(actionForm.ID);

  for (const rule of rules) {
    const filterObj = filterFields.find(filterField => filterField[fields.Name] === rule.destination);
    await updateFilterGoto({
      formID: actionForm.ID,
      fields: actionFormFields,
      filterObj,
      filter: rule.value
    });
  }
}

/**
 * Подготавливае приложение для перехода на новую форму.
 * Записывает в форму по actionFormID или formName фильтр.
 * Возвращает форму на которую нужно перейти и мод в котором ее нужно открыть.
 * @param {string} actionFormName
 * @param {FilterGotoRules[]} filters
 * @returns {Promise<SysForm>}
 */
export async function prepareGoTo({actionFormName}, filters) {
  let [actionForm, actionFormFields, filterObjects] = await loadDataForGoto({
    formName: actionFormName,
    filters
  });

  await updateFilterByRules(filters, filterObjects, actionForm, actionFormFields);

  return actionForm;
}

/**
 * данный метод проверяет входящие данные в Goto согласно документации
 * https://d5-doc.atlassian.net/wiki/spaces/D5SRS/pages/178683991/ButtonType+6
 * и возвращает текст ошибки в случае одного из кейсов
 *
 * @param selectedData {Array}
 * @param params {Object}
 * @param buttonRules {Array}
 * @returns {string}
 */
export const gotoChecker = ({selectedData, params, buttonRules}) => {
  if (!Array.isArray(buttonRules)) {
    throw new TypeError(`ButtonRules can be only Array`);
  }

  switch (true) {
    //если не выбрана ни одна строка
    case !selectedData.length:
      return Messages.Errors.AtLeastOneRowMustBeSelected;
    default:
      return '';
  }
};
/**
 * метод который конвертирует buttonRules в массив обьектов со структурой
 * @param buttonRules {Object[]}
 * @returns {FilterGotoRules[]}
 */
export const rulesToItems = buttonRules => {
  if (!Array.isArray(buttonRules)) {
    throw new TypeError(`ButtonRules must be only type of Array`);
  }

  return buttonRules.map(rule => {
    return {
      source: rule[fields.SourceFieldID_Name],
      destination: rule[fields.DestinationFilterID_Name],
      value: undefined
    };
  });
};

/**
 * метод который конвертирует customParams в массив обьектов со структурой
 * @param params {Object[]}
 * @returns {FilterGotoRules[]}
 */
export const paramsToItems = params => {
  if (!Array.isArray(params)) {
    throw new TypeError(`Params can be only Array`);
  }
  let items = [];
  if (params.length) {
    items = params.map(param => {
      return {
        source: param[fields.SourceAppObjectFieldName],
        destination: param[fields.DestAppObjectFilterName],
        value: undefined
      };
    });
    return items;
  }
  return items;
};

/**
 * Возвращает массив значений фильтра по всем строкам selectedData по полю sourceFieldName
 * @param {FormField} sourceField - поле из которого нужно взять значение для фильтра
 * @param {Object[]} selectedData - строки полученные из датасурса таблицы
 * @param {string} keyFld - ключевое поле формы
 */
export function getFilterForGoTo(sourceField, selectedData, keyFld) {
  let result = new Set();
  const systemKeyField = getSysField(keyFld);

  for (let row of selectedData) {
    let rowField = sourceField.name;

    if (keyFld === sourceField.objectFieldIDName) {
      rowField = systemKeyField;
    }

    const value = row[rowField];

    if (isArray(value)) {
      value.forEach(val => result.add(val));
    } else {
      result.add(value);
    }
  }

  return [...result].filter(isDefined);
}

/**
 * Если мы вставляем массив в селектбокс выдаем ошибку.
 * Если массив в обычное поле, то берем только первое значение.
 * Если массив в поле с признаком isMultiSelect, то оставляем массив.
 * @param {FilterField} destFilterField
 * @param {*[]} values
 * @returns {*}
 */
function checkDestinationValue(destFilterField, values) {
  const isMultiSelect = destFilterField.isMultiSelect;
  const isLookupOrEnum = destFilterField.isLookup() || destFilterField.isEnum();
  if (destFilterField.isCustomConditionList) {
    const isAnyOfIncluded = destFilterField.customConditionList.includes(FILTER_OPERATIONS_ID.isanyof);
    const isEqualIncluded = destFilterField.customConditionList.includes(FILTER_OPERATIONS_ID.equal);

    if (isLookupOrEnum && ((isMultiSelect && !isAnyOfIncluded) || (!isMultiSelect && !isEqualIncluded))) {
      showErrorNotification(Messages.Errors.DestinationFilterNotHaveCorrectCondition);
      throw new Error(Messages.Errors.DestinationFilterNotHaveCorrectCondition);
    }
  }
  if ((!isMultiSelect && isLookupOrEnum) || destFilterField.isDate()) {
    if (values.length > 1) {
      showErrorNotification(Messages.Errors.OnlyOneSelectedRowAllowedForThisAction);
      throw new Error(Messages.Errors.OnlyOneSelectedRowAllowedForThisAction);
    }
    return values[0];
  }
  return values;
}

/**
 * Устанавливает значения фильтра для переходов
 * @param {FilterGotoRules[]} items
 * @param {SysFormFields[]} formFields
 * @param {string} keyField
 * @param {Object[]} dataRows
 * @return {*}
 */
export function setValueToItems(items, formFields, keyField, dataRows) {
  return items.map(item => {
    const sourceField = formFields.find(field => new FormField(field).objectFieldIDName === item.source);
    if (!sourceField) {
      throw new Error(`GoTo error. SourceField was not found (${item.source})`);
    }
    return {
      ...item,
      value: getFilterForGoTo(new FormField(sourceField), dataRows, keyField)
    };
  });
}

export const wayToOpen = event => {
  const e = new MouseEventHelper(event);

  if (e.isLeftMouseKey() && e.ctrlPressed) {
    return IN_NEW_TAB;
  } else if (e.isLeftMouseKey() && e.shiftPressed) {
    return IN_NEW_WINDOW;
  }
  return IN_CURRENT;
};
