import {LOGIN_ROUTE, system} from 'services/objects';
import {isNotFound404Page, servicePrefixFromRoute} from './routes/routes';
import {NEW_WINDOW_OPENING_MODE} from 'services/interfaces/global-interfaces';
import {getKeyForAppSettings, getNewWindowOpenedMode} from 'services/SecondaryMethods/userSettings';
import {showErrorNotification} from '../services/SecondaryMethods/snackbars';
import {Messages} from '../services/lang/messages';

type ReturnUrls = {
  base: string;
  admin: string;
  configurator: string;
};

type State = {
  active: keyof ReturnUrls;
  urls: ReturnUrls;
};

type StorageReturnUrl = {route: string; storage?: Storage; userID?: number | null};

export abstract class IsSignOutAction {
  static get(): string | null {
    return sessionStorage.getItem(system.is_sing_out);
  }

  static set() {
    sessionStorage.setItem(system.is_sing_out, '1');
  }

  static empty() {
    return !IsSignOutAction.get();
  }

  static remove() {
    sessionStorage.removeItem(system.is_sing_out);
  }
}

export abstract class TemporaryReturnUrlForUnauthenticated {
  static get(): string | null {
    return sessionStorage.getItem('__unauthenticatedReturnUrl');
  }

  static set(url: string) {
    sessionStorage.setItem('__unauthenticatedReturnUrl', url);
  }

  static empty() {
    return !TemporaryReturnUrlForUnauthenticated.get();
  }

  static remove() {
    sessionStorage.removeItem('__unauthenticatedReturnUrl');
  }
}

const userStorageKeyValid = (userID?: number | null) => !getKeyForAppSettings(userID).startsWith('undefined');

export const getStorageReturnUrl = (userID?: number | null): State => {
  const key = getKeyForAppSettings(userID);

  const localStorageValue = localStorage.getItem(key) || '';
  const sessionStorageValue = sessionStorage.getItem(key) || '';
  const storageValue = sessionStorageValue || localStorageValue;

  const storageReturnUrl = JSON.parse(storageValue || '{}');

  if (!storageReturnUrl.hasOwnProperty('urls')) return storageReturnUrl;

  return {
    active: storageReturnUrl.active ?? 'base',
    urls: storageReturnUrl.urls ?? {
      base: storageValue,
      admin: '',
      configurator: ''
    }
  };
};

export const getOldReturnUrl = (): State => {
  const localStorageValue = localStorage.getItem(system.ReturnUrl) || '';
  const sessionStorageValue = sessionStorage.getItem(system.ReturnUrl) || '';
  const storageValue = sessionStorageValue || localStorageValue;
  if (storageValue[0] === '{') return JSON.parse(storageValue);

  return {
    active: 'base',
    urls: {
      base: storageValue,
      admin: '',
      configurator: ''
    }
  };
};

const setStorageReturnUrl = ({route, storage = localStorage, userID}: StorageReturnUrl) => {
  if (isNotFound404Page(route)) {
    return;
  }
  const state = getStorageReturnUrl();
  let key = servicePrefixFromRoute(route) || 'base';
  const keyAppSetts = getKeyForAppSettings(userID);
  if (!userStorageKeyValid(userID)) {
    return;
  }

  try {
    storage.setItem(
      keyAppSetts,
      JSON.stringify({
        ...JSON.parse(storage.getItem(keyAppSetts) || '{}'),
        ...{
          active: key,
          urls: {
            ...state.urls,
            [key]: route
          }
        }
      })
    );
  } catch (_) {
    showErrorNotification({msg: Messages.Errors.DataSavingError});
  }
};

const getLastActiveReturnUrlService = () => {
  return getStorageReturnUrl().active;
};

const getReturnUrl = (service: string, route: string): string => {
  if (!TemporaryReturnUrlForUnauthenticated.empty()) {
    const tempUrl = TemporaryReturnUrlForUnauthenticated.get()!;
    TemporaryReturnUrlForUnauthenticated.remove();
    return tempUrl;
  }

  const state = getStorageReturnUrl();
  const openingMode = getNewWindowOpenedMode();
  let key = service || 'base';
  if (openingMode === NEW_WINDOW_OPENING_MODE.HOME_URL && route !== LOGIN_ROUTE) {
    return '/';
  }
  return state.urls[key as keyof ReturnUrls] ?? '';
};

const setReturnUrl = (route: string, userID?: number | null) => {
  setStorageReturnUrl({route, userID});
  setStorageReturnUrl({route, storage: sessionStorage, userID});
};

export {setReturnUrl, getReturnUrl, getLastActiveReturnUrlService};
