import {FiltersDBItem} from './types';
import {inDB} from './initIndexedDB';
import {type Transaction, type TransactionMode} from 'dexie';
import {MinimizeFilter} from '../utilsOld/storageConverter/convertFilters';

export default abstract class FiltersDB {

  static putArray(items: {filterID: string; minimizedFilter: Record<string, MinimizeFilter>}[]) {
    return  FiltersDB.openTransaction('rw', async () => {
      for (const {filterID, minimizedFilter} of items) {
        FiltersDB.put({id: filterID, filters: minimizedFilter});
      }
    });
  }

  static put(filter: FiltersDBItem) {
    return inDB.filtersStore.put(filter);
  }

  static get(id: string) {
    return FiltersDB.openTransaction('r', () => inDB.filtersStore.get(id));
  }

  static getById(filterIds: string[]): Promise<FiltersDBItem[]> {
    if (!filterIds.length) {
      return Promise.resolve([]);
    }

    return inDB.filtersStore.where('id').anyOf(filterIds).toArray();
  }

  static getAllByUser(userID: number) {
    return inDB.filtersStore.where('id').startsWith(`${userID}-`).toArray();
  }

  static openTransaction<T = void>(mode: TransactionMode, callback: (tx?: Transaction) => Promise<T>): Promise<T> {
    return inDB.transaction(mode, inDB.filtersStore, callback);
  }

  static remove(filterId: string) {
    return inDB.filtersStore.delete(filterId);
  }

  static clear() {
    return inDB.filtersStore.clear();
  }
}
