import Dexie, {type EntityTable} from 'dexie';
import {FiltersDBItem, TableDBItem} from './types';
import {Messages} from '../services/lang/messages';

const inDB = new Dexie('director5') as Dexie & {
  tablesStore: EntityTable<TableDBItem, 'id'>;
  filtersStore: EntityTable<FiltersDBItem, 'id'>;
};

inDB.on('versionchange', function () {
  if (window.confirm(Messages.Confirm.UpdateDbScheme)) {
    // Refresh current webapp so that it starts working with newer DB schema.
    window.location.reload();
  } else {
    // Will let user finish its work in this window and
    // block the other window from upgrading.
    return false;
  }
});

inDB.version(1).stores({
  tablesStore: 'id, columns'
});

inDB.version(2).stores({
  filtersStore: 'id, filters'
});

const openDB = () => {
  return inDB.open();
};

export {inDB, openDB};
