import {system} from '../objects';
import {isEmptyObject} from '../SecondaryMethods/typeUtils';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUser} from '../interfaces/global-interfaces';

export const getCurrentUserForStorage = (userInfo: Record<string, any>): IUser => {
  return {
    servicePrefix: userInfo.servicePrefix,
    sid: userInfo.sid,
    UserID: userInfo.UserID,
    ID: userInfo.ID,
    PersonID: userInfo.PersonID
  }
}

const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');

const updateUserInfoInStore = (updateUserInfo: Record<string, any>) => {
  const newUserState = getCurrentUserForStorage({...currentUser, ...updateUserInfo});
  localStorage.setItem(system.current_user, JSON.stringify(newUserState));
  sessionStorage.setItem(system.current_user, JSON.stringify(newUserState));
};

export const AnonymousUserId = -6666;

type UserState = {
  isAuth: boolean;
  sid: string;
  Name: string;
  PersonID: number | undefined;
  UserID: number | undefined;
  IsConfigurator: boolean;
  IsAdmin: boolean;
  IsOwner: boolean;
  name: string;
  login: string;
  ID: number | undefined;
  Email: string | undefined;
  Phone: string | undefined;
  PublicKey: string | undefined;
  Photo: string | undefined;
  GoogleAuthEmail: string | null;
  IsNotify: boolean;
};

const emptyState = {
  isAuth: false,
  sid: '',
  Name: '',
  PersonID: undefined,
  UserID: undefined,
  IsConfigurator: false,
  IsAdmin: false,
  IsOwner: false,
  IsNotify: false,
  name: '',
  login: '',
  ID: undefined,
  Email: undefined,
  Phone: undefined,
  PublicKey: undefined,
  Photo: undefined,
  GoogleAuthEmail: null
} as UserState;

const defaultState = {
  ...emptyState,
  isAuth: !isEmptyObject(currentUser),
  sid: currentUser.sid || '',
  Name: currentUser.Name || '',
  PersonID: currentUser.PersonID || undefined,
  UserID: currentUser.UserID || undefined
} as UserState;

const {actions, reducer: userReducer} = createSlice({
  name: 'user',
  initialState: defaultState,
  reducers: {
    signInSuccess(state, action: PayloadAction<UserState>) {
      return {...state, ...action.payload, isAuth: true};
    },
    signOutSuccess() {
      return {...emptyState};
    },
    updateUserInfo(state, action: PayloadAction<Record<string, any>>) {
      updateUserInfoInStore(action.payload);
      return {...state, ...action.payload};
    }
  }
});

export {userReducer};
export const {signInSuccess, signOutSuccess, updateUserInfo} = actions;
