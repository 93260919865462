import store, {RootState} from '../../store';
import {system} from '../objects';

const {
  CONFIG_PARAMS: {
    USER_SETTINGS_IS_LOADED,
  }
} = system;

export const usSettingsFontsSelector = (state: RootState) => state.userSettings.customFonts;

export const usSettingsIsLoadedSelector = ({userSettings}: RootState) => userSettings.userSettingsIsLoaded;
export const diagramConfigSelector = ({userSettings}: RootState) => userSettings.diagramConfig;
export const messagesTimeoutsConfigSelector = ({userSettings}: RootState) => userSettings.messageTimeouts;
export const usWindowTitleSelector = ({userSettings}: RootState) => userSettings.windowTitle;

export const usTitleSelector = (state: RootState) => {
  const loaded = usSettingsIsLoadedSelector(state);
  return state.userSettings.title || loaded ? 'D5' : '';
};

export const usFaviconDefFileSelector = ({userSettings}: RootState) => {
  return userSettings?.favicon?.defaultFile || null;
};

export const usDocUrl = ({userSettings}: RootState) => {
  return userSettings.docURL;
};

export const homePageUrlSelector = ({userSettings}: RootState) => {
  return userSettings.homePageUrl;
};

export const usLogo = ({userSettings}: RootState) => {
  return userSettings.logo;
};

export const usTopPanelColor = ({userSettings}: RootState) => {
  return userSettings.topPanelColor;
};

export const userSettingsIsLoadedSelector = ({userSettings}: RootState) => {
  return userSettings[USER_SETTINGS_IS_LOADED];
};



export const singleFormApplicationSelector = ({userSettings}: RootState) => {
  return userSettings.singleFormApplication;
};

export const singleFormApplicationShared = () => {
  return singleFormApplicationSelector(store.getState());
};

export const usAllowedAuthTypes = ({userSettings}: RootState) => userSettings.loginPage.authType;

export const usDisabledPasswordSaving = ({userSettings}: RootState) => userSettings.loginPage.isDisabledPasswordSaving;
