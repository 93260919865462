import {FilterStateObject} from 'services/filters/types';
import {FormCreateMode, FormType, ICreateEditUrl, NEW_WINDOW_OPENING_MODE} from 'services/interfaces/global-interfaces';
import {SysForm} from 'services/interfaces/sysObjects';
import {Messages} from 'services/lang/messages';
import {fields, FILTER_URL, FORM_FILTER_URL, LIST_PART, NAV_EDIT_PART, SUBSYSTEM_STR, system} from 'services/objects';
import {getSubsystemFromState} from 'services/requests/operation';
import {formatToAPI} from 'services/SecondaryMethods/filterUtils';
import {showErrorNotification} from 'services/SecondaryMethods/snackbars';
import {isArray, isBoolean, isDate, isNumeric, isObject, isString} from 'services/SecondaryMethods/typeUtils';
import {getKeyForAppSettings, getNewWindowOpenedMode} from 'services/SecondaryMethods/userSettings';
import {createFilterQuery, encodeUrl} from 'utilsOld/filterQuery';
import {ReportType} from '../../services/SecondaryMethods/formItems/itemInterfaces';
import {singleFormApplicationShared} from '../../services/userSettings/selectors';
import {addServicePrefix, isEmptyObject} from '../helpers';
import {formatDateToAPI} from '../valueCasters';
/**
 * возвращает url для открытия формы редактирования
 */
export const createEditUrl = (props: ICreateEditUrl): string => {
  let {parentRoute = '/', mode, formName, id} = props;
  let editPart: string | number = '';
  const {MULTI_EDIT, EDIT, ADD} = FormCreateMode;

  if (!mode) {
    throw new Error("Editing Mode can't be empty");
  }
  if (mode === ADD && id) {
    throw new Error("Add Mode can't be with ID");
  }
  if (mode === MULTI_EDIT || (mode === EDIT && id)) {
    editPart = id || '';
  }

  if (parentRoute[parentRoute.length - 1] !== '/') parentRoute += '/';
  return `${parentRoute}${mode}/${formName.toLowerCase()}/${editPart}`;
};

export const createSectionRoute = (subsystemName: string) => {
  return `/${SUBSYSTEM_STR}/${subsystemName.toLowerCase()}`;
};

export const createNavEditRoute = (formName: string, parentRoute = '') => {
  return parentRoute + `/${NAV_EDIT_PART}/${formName}`;
};

export const createListRoute = (formName: string, parentRoute = '', type: FormType) => {
  return parentRoute + `/${LIST_PART}/${formName}/${type}`;
};

/**
 * возвращает url для открытия формы
 * @param location {Object} обьект window location
 * @param name {string} имя сабсистемы
 * @param servicePrefix {string} имя сабсистемы
 */
export const createSubSystemUrl = (location: Record<string, any>, name: string, servicePrefix: string): string => {
  const {origin, pathname} = location;
  if (!name) {
    throw new Error("Name can't be empty");
  }
  return `${origin}${pathname}#/${servicePrefix ? servicePrefix + '/' : ''}${SUBSYSTEM_STR}/${name}`;
};

/**
 * создает родительский путь т.е. из какого каталога строиться путь
 * @param hash document.location.hash
 * @example /section/subsysname
 */
export const createParentRoute = (hash: string): string => {
  /**
   * @example /admin/section/countries
   * @example /configurator/section/countries
   * @example /section/countries
   */

  const sectionPart = hash.match(/\/((admin|configurator)\/)?section\/\w+/)?.[0];
  if (sectionPart) {
    return sectionPart;
  }

  const formPart = hash.match(/(\/form(\/[^\/]+)?)(?=\/(edit|add|addChild|multiEdit)\/|\/?$)/)?.[1];
  if (formPart) {
    return formPart;
  }

  return '/';
};

export const subsystemNameFromRoute = (subsystemName: string) => {
  return subsystemName.match(/\/section\/(\w+)/)?.[1];
};

export const createModeFromPath = (path: string): FormCreateMode | null => {
  return path.match(/^\/(?:configurator\/)?(section\/\w+\/)?(edit|add|addChild|multiEdit)\/.+/)?.[2] as FormCreateMode;
};

export const servicePrefixFromRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {ADMIN, CONFIGURATOR, FORM}
  } = system;

  const cleanRoute = routeFromHash(route);
  const prefix = cleanRoute.split('/')[1] ?? '';
  return prefix === ADMIN || prefix === CONFIGURATOR || prefix === FORM ? prefix : '';
};

export const isAdminRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {ADMIN}
  } = system;
  return servicePrefixFromRoute(route) === ADMIN;
};

export const isConfigRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {CONFIGURATOR}
  } = system;
  return servicePrefixFromRoute(route) === CONFIGURATOR;
};
export const isSingleFormRoute = (route: string) => {
  const {
    SERVICE_PREFIX: {FORM}
  } = system;
  return servicePrefixFromRoute(route) === FORM;
};

export const isNotFound404Page = (route: string) => {
  return route.match(/^\/?404\/?$/);
};

export const isDevTestRoutePage = (route: string) => {
  return route.match(/^\/?test(?!\w)/);
};

export const redirectToFilterLink = (link: string) => {
  return link.replace(SUBSYSTEM_STR, FILTER_URL);
};

export const createStoreFilterUtl = ({
  sysForm,
  storeFilters,
  servicePrefix = '',
  newApiFilter = {}
}: {
  sysForm: SysForm;
  storeFilters: Record<string, FilterStateObject>;
  servicePrefix?: string;
  newApiFilter?: Record<string, any>;
}) => {
  const apiFilter = formatToAPI({storeFilters, sysForm});
  if (isEmptyObject(apiFilter)) {
    showErrorNotification({title: Messages.Errors.ErrorOccurred, msg: Messages.Errors.FilterNotSet});
    throw new Error('Filter is not set');
  }

  return createApiFilterUtl(sysForm.Name, Object.assign(newApiFilter, apiFilter), servicePrefix);
};

const createApiFilterUtl = async (formName: string, apiFilter: Record<string, any>, servicePrefix = '') => {
  const {
    location: {origin, pathname}
  } = window;

  if (isEmptyObject(apiFilter)) {
    return null;
  }

  const createRoute = async (): Promise<string> => {
    const useSubsystemUrl = !singleFormApplicationShared();
    const prefixWithSlash = servicePrefix ? addServicePrefix(servicePrefix) + '/' : '/';

    if (useSubsystemUrl) {
      const subsystem = await getSubsystemFromState({formName});
      if (isEmptyObject(subsystem)) {
        throw new Error('Subsystems are empty!');
      }

      const subsystemName = subsystem[fields.Name].toLowerCase();

      return `${prefixWithSlash}${FILTER_URL}/${subsystemName}`;
    }

    return `${prefixWithSlash}${FORM_FILTER_URL}/${formName}`;
  };

  const route = await createRoute();

  return `${origin}${pathname}#${route}?filter=${encodeUrl(createFilterQuery(apiFilter))}`;
};

export const checkNewWindowOpenedMode = () => {
  const newWindowMode = getNewWindowOpenedMode();
  if (newWindowMode) {
    return newWindowMode;
  } else {
    const key = getKeyForAppSettings();
    const appSetts = JSON.parse(localStorage.getItem(key) || '{}');
    const appSettings = {
      ...appSetts,
      new_window_opened_mode: NEW_WINDOW_OPENING_MODE.LAST_OPENED_FORM
    };
    localStorage.setItem(key, JSON.stringify(appSettings));
  }
};

const getReportViewString = (reportType: ReportType) => {
  return `wapi/${reportType === ReportType.TypeForm ? 'dxreport' : 'xlsreport'}/viewer`;
};

export const createReportListLink = ({
  reportName,
  selectedKeys,
  queryString = '',
  reportType
}: {
  reportName: string;
  selectedKeys: any[];
  queryString: string;
  reportType: ReportType;
}) => {
  if (queryString && !queryString.startsWith('&')) throw new Error('queryString must start with &');

  //коли в масиві одне значення, то його екранувати в кавички не потрібно (така реалізація на сервері)
  const keyValueToArray = selectedKeys.length > 1 ? defaultValueReportQueryParamGetter(selectedKeys) : selectedKeys[0];
  return `${getReportViewString(reportType)}/${reportName}?keyvalue=${keyValueToArray}${queryString}`;
};

/**
 * Парсит хеш с window.location и убирает #, чтобы получить роут
 */
export function routeFromHash(hash: string) {
  let result = hash.replace(/^\/#|^#/, '');
  return result.replace(/\/$/, '') || '/';
}

/**
 * Конвертує значення яке буде передано в reportQueryParamsToString
 */
export const defaultValueReportQueryParamGetter = (value: any, isArrayItems = false): string => {
  const formatNumeric = (v: number) => v.toString();
  const formatBoolean = (v: boolean) => formatNumeric(+v);
  const formatString = (v: string) => (!v || !isArrayItems ? v : `"${v}"`);
  const formatArray = (v: any[]) => `[${v.map(i => defaultValueReportQueryParamGetter(i, true)).join(',')}]`;

  if (isNumeric(value)) return formatNumeric(value);

  if (isBoolean(value)) return formatBoolean(value);

  if (isDate(value)) return formatDateToAPI(value, false);

  if (isString(value)) return formatString(value);

  if (isArray(value)) return formatArray(value);

  return JSON.stringify(value);
};

/**
 * Створює рядок query з переданого об'єкту params.
 * Результат функції буде просто додаватись в урлу без додаткової обробки.
 */
export function reportQueryParamsToString(params: Record<string, string | number> | undefined): string {
  if (!isObject(params)) return '';

  const queryParams = Object.keys(params!)
    .map(key => `${key}=${params![key]}`)
    .join('&');

  return queryParams && `&${queryParams}`;
}

export const matchFilter = (pathname: string) => {
  return pathname.match(/^(?:\/configurator)?\/filter\//);
};
