import {FilterStateObject} from '../../filters/types';

export const resetFilter = (filter: FilterStateObject): Partial<FilterStateObject> => {
  const newFilter = {...filter};
  //@ts-ignore
  delete newFilter.operation;
  delete newFilter.value;
  delete newFilter.displayValue;
  delete newFilter.hasIsBlank;

  return newFilter;
};
