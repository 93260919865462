import axios from 'axios';
import {Spinner} from 'components/spinner';
import useHistory from 'components/useHistory';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {IUser, IUserInfo} from 'services/interfaces/global-interfaces';
import {isEmptyObject} from 'services/SecondaryMethods/typeUtils';
import {getLastActiveReturnUrlService, getReturnUrl, IsSignOutAction, setReturnUrl} from 'utilsOld/returnUrl';
import {
  urlActivation,
  urlChangePass,
  urlCheckLogin,
  urlRestorePass,
  urlSignIn,
  urlSignOut,
  urlSignUp,
  urlUserInfo
} from './services/baseUrl';
import {
  ADMIN_PANEL_ROUTE,
  CONFIGURATOR_PANEL_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  REGISTRATION_BY_INVITE_ROUTE,
  RESTORE_PASSWORD,
  system
} from './services/objects';
import {checkAppSettings, convertedDataResponse} from './services/SecondaryMethods/userSettings';
import useInitAxios from './useInitAxios';
import {checkNewWindowOpenedMode, routeFromHash, servicePrefixFromRoute} from './utilsOld/routes/routes';
import {AnonymousUserId, getCurrentUserForStorage} from './services/user/reducer';
import {showErrorNotification} from './services/SecondaryMethods/snackbars';
import {Messages} from './services/lang/messages';
import {useLocation} from 'react-router';

const RETURN_LAST_SAVE_URL_ROUTES = [LOGIN_ROUTE, RESTORE_PASSWORD, REGISTRATION_ROUTE, REGISTRATION_BY_INVITE_ROUTE];
const isReturnLastSaveUrl = (route: string) => RETURN_LAST_SAVE_URL_ROUTES.includes(route);

const saveReturnUrl = (userID?: number | null) => {
  const route = routeFromHash(window.location.hash);

  if (isReturnLastSaveUrl(route)) return;

  setReturnUrl(route, userID);
};

export interface AuthContextInterface {
  isAuth: boolean;
  user: IUser | null;
  signIn: (props: {Login?: string; Password?: string; GoogleAuthCode?: string}) => Promise<void>;
  activationFunction: (code: string) => Promise<void>;
  restorePassword: (email: string, emailOrLogin: string) => Promise<void>;
  changePassword: (credentials: {
    ActivationCode?: string;
    OldPassword?: string;
    Password: string;
    Login?: string;
  }) => Promise<Record<string, any>>;
  signOut: (userInteraction?: boolean) => void;
  isAnonymous: boolean;
  setAnonUser: () => Promise<void>;
  getUserProfileInfo: (columns?: string[]) => Promise<Record<string, any>>;
  signUp: (args: {
    Login?: string;
    Name?: string;
    EMail?: string;
    Password?: string;
    InviteCode?: string | null;
    GoogleAuthCode?: string;
  }) => Promise<void>;
}

const {
  INPUT_PLACEHOLDERS: {EMAIL}
} = system;

const AuthContext = createContext<AuthContextInterface | null>(null);
const useAuth = (): Partial<AuthContextInterface> => useContext(AuthContext) || {};

export const clearStorage = () => {
  localStorage.removeItem(system.current_user);
};

function saveUserToStorage(newUser: IUser) {
  try {
    localStorage.setItem(system.current_user, JSON.stringify(newUser));
  } catch (_) {
    showErrorNotification({msg: Messages.Errors.DataSavingError});
  }
  sessionStorage.setItem(system.current_user, JSON.stringify(newUser));
}

const AuthProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [user, setUser] = useState<IUserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    window.addEventListener('beforeunload', () => {
      saveReturnUrl(user.UserID);
    });

    return () => {
      saveReturnUrl(user.UserID);
      window.removeEventListener('beforeunload', () => {
        saveReturnUrl(user.UserID);
      });
    };
  }, [location.pathname, user]);

  const processLogin = async (newUser: IUserInfo) => {
    navigateToReturnUrl();
    setUser(newUser);
  };

  const createAnonUser = (): IUserInfo => {
    return {
      Name: 'Anonymous',
      PersonID: AnonymousUserId,
      UserID: AnonymousUserId,
      servicePrefix: '',
      sid: ''
    };
  };

  const setAnonUser = () => {
    saveUserToStorage(getCurrentUserForStorage(createAnonUser()));
    return processLogin(createAnonUser());
  };

  function navigateToReturnUrl() {
    const route = routeFromHash(window.location.hash);
    const service = isReturnLastSaveUrl(route)
      ? getLastActiveReturnUrlService()
      : servicePrefixFromRoute(window.location.hash);

    const returnRoute = getReturnUrl(service, route);

    if (
      [CONFIGURATOR_PANEL_ROUTE, ADMIN_PANEL_ROUTE, LOGIN_ROUTE, '/', ...RETURN_LAST_SAVE_URL_ROUTES].includes(route)
    ) {
      history.replace(returnRoute || '/');
    }
  }

  const signIn = async (signInProps: {Login?: string; Password?: string; GoogleAuthCode?: string}) => {
    const headerDefault = {};
    const options = {
      method: 'POST',
      headers: headerDefault,
      url: urlSignIn,
      data: {
        [system.REQUEST]: {
          [system.SYS_LOGIN]: signInProps
        }
      }
    } as const;

    await axios(options)
      .then(async e => {
        const {data, status} = e;

        if (status === 401) {
          // @ts-ignore
          return Promise.reject(new Error(e.response.data.ResponseText));
        }

        if (data.ResponseCode !== '000') {
          return Promise.reject(new Error(data.ResponseText));
        }
        const userProfile = await getUserProfileInfo();
        const newUser = convertedDataResponse(data.Response, signInProps.Login ?? '', userProfile);

        saveUserToStorage(getCurrentUserForStorage(newUser));

        checkAppSettings();
        checkNewWindowOpenedMode();
        await processLogin(newUser);
      })
      .catch(e => {
        throw new Error(e.message ?? e.response.data.ResponseText);
      });
  };
  const getUserProfileInfo = async (
    columns: string[] = [
      'Name',
      'Email',
      'Phone',
      'PublicKey',
      'Photo',
      'GoogleAuthEmail',
      'IsNotify',
      'IsAdmin',
      'IsConfigurator',
      'IsOwner',
      'Login'
    ]
  ) => {
    const headerDefault = {};
    const options = {
      method: 'POST',
      headers: headerDefault,
      data: {
        Columns: columns
      },
      url: urlUserInfo
    } as const;

    try {
      const {data} = await axios(options);
      if (data.ResponseCode !== '000') {
        throw new Error(data.ResponseText);
      }
      return data.Response[system.SYS_PROFILE][0];
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const checkLogin = async () => {
    const options = {
      method: 'GET',
      url: urlCheckLogin
    } as const;

    try {
      const {data} = await axios(options);
      return data;
    } catch {
      return {ResponseCode: '401'};
    }
  };

  const signUp = (creds: {
    Login?: string;
    Name?: string;
    EMail?: string;
    Password?: string;
    GoogleAuthCode?: string;
  }) => {
    clearStorage();
    const headerDefault = {};

    const option = {
      method: 'POST',
      headers: headerDefault,
      url: urlSignUp,
      data: {
        [system.REQUEST]: {
          [system.SYS_LOGIN]: creds
        }
      }
    } as const;

    return axios(option).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };

  //  user activation
  const activationFunction = (code: string) => {
    clearStorage();

    const options = {
      method: 'POST',
      url: urlActivation,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: {ActivationCode: code}}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data);
    });
  };

  const restorePassword = (value: string, emailOrLogin: string) => {
    const body = emailOrLogin === EMAIL ? {EMail: value} : {Login: value};
    clearStorage();
    const options = {
      method: 'POST',
      url: urlRestorePass,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: body}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };
  //  user changePassword
  const changePassword = (credentials: {
    ActivationCode?: string;
    Password: string;
    OldPassword?: string;
    Login?: string;
  }) => {
    const options = {
      method: 'POST',
      url: urlChangePass,
      contentType: 'Application/jsoncharset=UTF-8',
      data: {[system.REQUEST]: {[system.SYS_LOGIN]: credentials}}
    } as const;

    return axios(options).then(({data}) => {
      if (data.ResponseCode !== '000') {
        return Promise.reject(new Error(data.ResponseText));
      }
      return Promise.resolve(data.Response);
    });
  };

  const signOut = (userInteraction = false) => {
    const options = {
      method: 'POST',
      url: urlSignOut
    } as const;

    axios(options);
    clearStorage();
    userInteraction && IsSignOutAction.set();
    setUser(null);
  };

  useInitAxios({signOut});

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}') as IUser;

    if (isEmptyObject(currentUser)) {
      return setLoading(false);
    }

    (async function () {
      let resp = await checkLogin();

      if (resp?.ResponseCode === '000') {
        const userProfile = await getUserProfileInfo();
        await processLogin({...currentUser, ...userProfile});
      }
      setLoading(false);
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!user,
        isAnonymous: user?.UserID === AnonymousUserId,
        user,
        signIn,
        signUp,
        signOut,
        changePassword,
        restorePassword,
        setAnonUser,
        activationFunction,
        getUserProfileInfo
      }}
    >
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export {useAuth};
