import {FIELD_EDITOR_TYPE, FILTER_OPERATIONS, USmallInt} from '../../services/interfaces/global-interfaces';
import {booleanToNumber, numberToBoolean} from './utils';
import {isEmptyValue} from '../../services/SecondaryMethods/typeUtils';

export interface MaximizeFilter {
  value: any;
  operation: FILTER_OPERATIONS;
  hasIsBlank: boolean;
  type: FIELD_EDITOR_TYPE;
  isTable: boolean;
  requestField: string;
}

export interface MinimizeFilter {
  v: any;
  o: FILTER_OPERATIONS;
  hib: USmallInt;
  t: FIELD_EDITOR_TYPE;
  it: USmallInt;
  rf: string;
}

export const minimizeFilters = (filters: Record<string, MaximizeFilter>): Record<string, MinimizeFilter> => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key];

    const isAlreadyMinimized = Object.keys(filter).some(key => ['t', 'o'].includes(key));

    if (isAlreadyMinimized)
      return {
        ...acc,
        [key]: filter
      };

    return {
      ...acc,
      [key]: {
        v: filter.value,
        o: filter.operation,
        hib: booleanToNumber(filter.hasIsBlank),
        t: filter.type,
        it: booleanToNumber(filter.isTable),
        rf: filter.requestField
      } as MinimizeFilter
    };
  }, {});
};

export const maximizeFilters = (filters: Record<string, MinimizeFilter>): Record<string, MaximizeFilter> => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key];

    const isAlreadyMaximized = Object.keys(filter).some(key => ['type', 'operation'].includes(key));

    if (isEmptyValue(filter) || isAlreadyMaximized) {
      return {
        ...acc,
        [key]: filter
      };
    }

    return {
      ...acc,
      [key]: {
        value: filter.v,
        operation: filter.o,
        hasIsBlank: numberToBoolean(filter.hib),
        type: filter.t,
        isTable: numberToBoolean(filter.it),
        requestField: filter.rf
      } as MaximizeFilter
    };
  }, {});
};
