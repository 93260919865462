import {Column} from 'components/TableCoreOld/columns/index';
import {Locale} from 'services/interfaces/global-interfaces';
import {isNumeric} from 'services/SecondaryMethods/typeUtils';
import {formatText} from '../../../utilsOld/valueCasters';
import {ColumnClass} from '../types';
import HeaderFilterDataSource from './HeaderFilterDataSource';

export default class NumberColumn extends Column {
  textPretty = text => {
    if (!isNumeric(text)) return null;
    return formatText('number', text, this.regSetting, this.field.getFieldFormat());
  };

  headerFilterLoadFun = instance => {
    const ds = new HeaderFilterDataSource({
      loadRows: instance.getAllRowsData.bind(instance),
      valueGetter: this.headerFilterValueGetter,
      textPrettier: this.textPretty
    });
    return ds.createLoadFun();
  };

  setTextFormat = () => {
    const format = this.field.getFieldFormat() ?? 'decimal';
    return {format};
  };

  handlePaste = e => {
    const {event, component} = e;

    event.preventDefault();
    const clipboardData = event.originalEvent.clipboardData.getData('text');
    const value =
      this.regSetting === Locale.en
        ? parseFloat(clipboardData.replace(/,/g, ''))
        : parseFloat(clipboardData.replace(/ /g, '').replace(/,/g, '.'));

    if (!isNaN(value)) {
      component.option('value', value);
    }
  };

  prop() {
    const props = super.prop();
    const textFormat = this.setTextFormat();

    return {
      ...props,
      dataType: 'number',
      cssClass: ColumnClass.Number,
      editorOptions: {
        step: 0,
        onPaste: this.handlePaste
      },
      headerFilter: this.createHeaderFilter(),
      ...textFormat
    };
  }
}
